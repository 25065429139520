
$text-color: #027B99;
$theme-color: #006778;        /* ARVO BRAND */
$theme-color-hover: #0a444e;  /* ARVO DARK */

$font-body: Verdana, Arial, sans-serif;
$font-heading: Verdana, Arial, sans-serif;
$font-heading-light: Verdana, Arial, sans-serif;
$font-size-body: 14px;

h2 {
  font-size: 20px;
}


@media (max-width: 750px) {
  html {
    font-size: 0.8em;
  }
}
