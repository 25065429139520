.dl-row {
  border-bottom: 1px solid #949494;
  font-size: 1.4rem;
}

dt, dd {
  margin: 8px 0;
  display: inline-block;
  vertical-align: middle;
}

dt {
  width: 30%;
}

dt::after {
  content: ":";
}

dd {
  margin-left: 2rem;
  text-align: left;
  padding-left: 10px;
}

@media (max-width: 900px) {
  dt {
    width: 50%;
  }
}
