
#theme {
  background: url("../../images/AVOP_teema.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  margin-bottom: 3rem;

  #logo {
    margin: 1.2rem 0 .5rem 0;

    img {
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}

#login {
  margin-top: 2rem;
  margin-bottom: 2rem;
  #haka-login {
    box-sizing: border-box;
    max-width: 100%;
    word-wrap: break-word;

    #haka {
      img {
        max-width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

.centered {
  text-align: center;
}
