/* Base Application Styles */

@use '../styles/globals';

body {
  line-height: 1.6;
  color: #000;
  font-family: globals.$font-body;
  font-size: globals.$font-size-body;
}

a {
  text-decoration: none;
  color: globals.$theme-color;

  &:hover {
    color: globals.$theme-color-hover;
    text-decoration: underline;
  }
}

.separator {
  border-top: 1px solid #eee;
}

.hidden {
  display: none;
}

button {
  background-color: #00b5e2;
  color: #fff;
}
